import { WithFields } from "@/api";
import { Cdec, CompanyInfo } from "@/api/alarm";
import { useResizeObserver } from "@mb-pro-ui/components";
import { useGetAll, useGetOne, useUpdate } from "@mb-pro-ui/utils";
import type { JsonapiError } from "@mb-pro-ui/utils/jsonapi/types";
import CallIcon from "@mui/icons-material/Call";
import DoneIcon from "@mui/icons-material/Done";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import SmsIcon from "@mui/icons-material/Sms";
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  SxProps,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import DOMPurify from "dompurify";
import qrcode from "qrcode-generator";
import { useLayoutEffect, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { JsonApiErrorSnackbar } from "../../hooks/useErrorHandler";
import useMe from "../../hooks/useMe";
import useTimestampFormat from "../../locales/useTimestampFormat";
import EmailButton from "../email";
import { SnackbarState } from "../settings/types";
import { Snackbar } from "../settings/utils";
import BootstrapTooltip from "../utils/BootstrapTooltip";
import {
  StyledIconButton,
  StyledTooltip,
} from "../utils/StyledHeaderComponents";
import Widget from "../utils/Widget";
import { useAcknowledge } from "./AcknowledgeContext";
import ManualSmsModal from "./ManualSmsModal";
import { ActionGroup, Intervention, Subtask, TaskGroup } from "./types";

const getProtocol = (code: string | null) => {
  switch (code) {
    case "C":
      return "tel:";
    case "E":
      return "mailto:";
    case "S":
      return "sms:";
    default:
      return "";
  }
};

function buildHref(protocol: string, address: string | null, body: string) {
  return protocol && address
    ? `${protocol}${address}${body ? `?&body=${encodeURIComponent(body)}` : ""}`
    : null;
}

type SMSCdec = WithFields<Cdec, { cdec: ["arrived", "localized-description"] }>;

function renderQrCode(
  content: string,
  {
    cellSize = 2,
    backgroundColor = "transparent",
    foregroundColor = "black",
  }: { cellSize?: number; backgroundColor?: string; foregroundColor?: string },
) {
  const qr = qrcode(0, "L");
  qr.addData(content);
  qr.make();

  const moduleCount = qr.getModuleCount();
  const ctx = document.createElement("canvas").getContext("2d");
  if (!ctx) {
    return null;
  }

  ctx.canvas.width = cellSize * moduleCount;
  ctx.canvas.height = cellSize * moduleCount;

  if (backgroundColor !== "transparent") {
    ctx.fillStyle = backgroundColor;
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  }

  ctx.fillStyle = foregroundColor;
  for (var r = 0; r < moduleCount; r += 1) {
    for (var c = 0; c < moduleCount; c += 1) {
      if (qr.isDark(r, c)) {
        ctx.fillRect(c * cellSize, r * cellSize, cellSize, cellSize);
      }
    }
  }

  return ctx.canvas.toDataURL();
}

function useSMSBody(
  enabled: boolean,
  account?: string,
  company?: string | null,
  cdec?: SMSCdec[],
) {
  const { formatTimestamp: formatTime } = useTimestampFormat("time");

  if (!enabled) {
    return "";
  }

  account = account ? `${account}: ` : "";
  company = company ? `|${company}` : "";

  const events =
    cdec?.map(
      (it) => `${formatTime(it.arrived)} ${it["localized-description"]}`,
    ) ?? [];

  return account + events.join("|") + company;
}

function normalizePossibleRichText(text?: string | null) {
  if (!text) {
    return "";
  }
  if (text.indexOf("<") === -1) {
    if (text.indexOf("\n") === -1) {
      return text;
    }
    return text
      .split("\n")
      .map((line) => {
        line = line.trim();
        return line ? `<span>${line}</span><br>` : "<br>";
      })
      .join("\n");
  }
  return DOMPurify.sanitize(text);
}

const StyledImg = styled("img")({});

const StQrCode = ({
  dataUrl,
  smsBody,
}: {
  dataUrl: string;
  smsBody: string | null;
}) =>
  smsBody ? (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="caption" color="primary">
        {smsBody}
      </Typography>
      <StyledImg src={dataUrl} alt="QR Code" sx={{ display: "block" }} />
    </Box>
  ) : (
    <StyledImg src={dataUrl} alt="QR Code" sx={{ display: "block" }} />
  );

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    borderColor: theme.palette.divider,
    borderWidth: "1px",
    borderStyle: "solid",
    padding: theme.spacing(0.5),
  },
}));

const LimitedHeightContent = ({
  content,
  width,
}: {
  content: string;
  width: number | undefined;
}) => {
  const [isMultiline, _setIsMultiline] = useState(true);
  const target = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    const { current } = target;
    if (!current) {
      return;
    }

    const { scrollHeight } = current;
    const { height = Infinity } = current.getClientRects()[0] ?? {};

    _setIsMultiline(scrollHeight > height);
  }, [target]);

  useResizeObserver(target, (entry) => {
    const { scrollHeight } = entry.target;
    _setIsMultiline(scrollHeight > entry.contentRect.height);
  });

  const visibleContent = (
    <Typography
      component="div"
      variant="body2"
      textOverflow="ellipsis"
      sx={{
        "& > p": { margin: 0 },
        maxHeight: "calc(1.43em * 2)",
        overflow: "hidden",
        marginTop: 0.5,
      }}
      ref={target}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );

  const fullContent = (
    <Typography
      component="div"
      variant="body2"
      textOverflow="ellipsis"
      sx={{ "& > p": { margin: 0 } }}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
  return isMultiline ? (
    <HtmlTooltip title={fullContent} placement="left" arrow>
      <Box
        sx={{
          display: "flex",
          backgroundColor: `rgba(0, 0, 0, 0.1)`,
          width: width,
          p: 0.5,
          position: "relative",
        }}
      >
        {visibleContent}
        <MoreHorizIcon
          sx={{
            alignSelf: "flex-end",
            ml: 0.5,
          }}
        />
      </Box>
    </HtmlTooltip>
  ) : (
    visibleContent
  );
};

export const normalizePhoneNumber = (phoneNumber: string) =>
  phoneNumber.replace(/[^0-9+]/g, "");

export const useNormalizedRichText = (text?: string | null) =>
  useMemo(() => normalizePossibleRichText(text), [text]);

export const StyledLink = styled("a")(({ theme }) => ({
  fontFamily: theme.typography.fontFamily,
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.body2.fontWeight,
}));

const STListItem = ({
  it,
  st,
  ag,
  cmp,
  readonly,
  setSubtaskStatus,
  separator,
  sx = [],
}: {
  it?: Intervention;
  st: Subtask;
  ag: ActionGroup | null;
  cmp: CompanyInfo | null;
  readonly?: boolean;
  setSubtaskStatus: (id: string, status: boolean) => void;
  separator?: boolean;
  sx?: SxProps;
}) => {
  const protocol = getProtocol(st.code);
  const isSMS = protocol === "sms:";

  const { data: smsCdecData } = useGetAll<SMSCdec>("alarm/cdec", {
    fields: {
      cdec: ["arrived", "localized-description"],
    },
    filter: {
      intervention: { eq: it?.id },
      "category-alert": { is: "true" },
      "event-category": { in: ag?.rules?.map((rule) => rule.category) },
    },
    sort: ["id"],
    page: {
      limit: 10,
    },
    enabled: isSMS && !!it,
  });

  const { data: user } = useMe();
  const { formatMessage } = useIntl();
  const [manualSmsModalOpen, setManualSmsModalOpen] = useState(false);
  const [snackbarState, setSnackbarState] = useState<
    SnackbarState | undefined
  >();
  const snackbarOnClose = () => {
    setSnackbarState({ message: undefined });
  };

  const [width, setWidth] = useState<number | undefined>();
  const [controlWidth, setControlWidth] = useState<number | undefined>();

  const target = useRef<HTMLDivElement>(null);
  const labelTarget = useRef<HTMLElement>(null);

  useResizeObserver(target, () => {
    setWidth(labelTarget.current?.clientWidth);
  });

  const customerAccount = it?.customer?.account;
  const companyName = cmp?.name;

  const smsBody = useSMSBody(isSMS, customerAccount, companyName, smsCdecData);

  const cleanedAddress =
    protocol === "tel:" || protocol === "sms:"
      ? normalizePhoneNumber(st.address || "")
      : st.address;

  const href = buildHref(protocol, cleanedAddress, smsBody);

  const dataUrl = useMemo(
    () => (href ? renderQrCode(href, { cellSize: 4 }) : null),
    [href],
  );

  const description = useNormalizedRichText(st.description);
  const remark = useNormalizedRichText(st["person-remark"]);

  const address = st.address ? (
    href ? (
      <StyledLink href={href}>{st.address}</StyledLink>
    ) : (
      <Typography variant="body2">{st.address}</Typography>
    )
  ) : null;

  const text = (
    <>
      <Typography
        component="div"
        variant="subtitle2"
        textOverflow="ellipsis"
        sx={{ "& > p": { margin: 0 } }}
        dangerouslySetInnerHTML={{ __html: description }}
      />
      <Typography variant="body2">{st.person}</Typography>
      {dataUrl && address ? (
        <HtmlTooltip
          title={<StQrCode dataUrl={dataUrl} smsBody={smsBody} />}
          placement="left"
          arrow
        >
          {address}
        </HtmlTooltip>
      ) : (
        address
      )}
      {remark ? (
        <LimitedHeightContent
          content={remark}
          width={width && controlWidth && width - controlWidth}
        />
      ) : null}
    </>
  );

  const manaualSmsAllowed =
    user?.["alarm-operator"]?.admin || user?.["alarm-operator"]?.["manual-sms"];

  return (
    <Box
      key={st.id}
      ref={target}
      display="block"
      width="100%"
      px={2}
      sx={[
        { paddingTop: 0, paddingBottom: 0, position: "relative" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box display="flex" flexDirection="row">
        <FormControlLabel
          sx={{ width: "100%" }}
          ref={labelTarget}
          control={
            <Box ref={(e: HTMLElement) => setControlWidth(e?.clientWidth)}>
              {readonly ? (
                st.status ? (
                  <DoneIcon color="primary" sx={{ m: 1, alignSelf: "start" }} />
                ) : (
                  <Box m={1} width="24px" flexShrink={0} />
                )
              ) : (
                <Checkbox
                  onChange={(e) => setSubtaskStatus(st.id, e.target.checked)}
                  disabled={!ag || readonly}
                  checked={st.status}
                  disableRipple
                  sx={{
                    marginRight: "20px",
                    marginLeft: 0,
                    alignSelf: "start",
                  }}
                />
              )}
            </Box>
          }
          label={text}
          disabled={!ag}
        />
        {protocol === "sms:" && !!st.address && manaualSmsAllowed ? (
          <StyledTooltip
            title={
              manaualSmsAllowed
                ? formatMessage({
                    defaultMessage: "Manual SMS",
                  })
                : formatMessage({
                    defaultMessage:
                      "Only operators with manual-sms right and admins allowed to send manual sms",
                  })
            }
          >
            <StyledIconButton
              onClick={() => setManualSmsModalOpen(true)}
              sx={{
                padding: 0,
                marginTop: 1,
                position: "absolute",
                top: 0,
                right: "16px",
              }}
            >
              <SmsIcon />
            </StyledIconButton>
          </StyledTooltip>
        ) : null}
        {protocol === "mailto:" && !!st.address && (
          <EmailButton
            type="intervention"
            initialValues={{ to: st.address }}
            intervention={it}
            customer={it?.customer}
            sx={{
              padding: 0,
              marginTop: 1,
              position: "absolute",
              top: 0,
              right: "16px",
            }}
            templateContext={["customer", "intervention"]}
          />
        )}
        {protocol === "tel:" && !!st.address && (
          <StyledTooltip
            title={formatMessage({
              defaultMessage: "Call",
            })}
          >
            <StyledIconButton
              component="a"
              href={href}
              sx={{
                padding: 0,
                marginTop: 1,
                position: "absolute",
                top: 0,
                right: "16px",
              }}
            >
              <CallIcon />
            </StyledIconButton>
          </StyledTooltip>
        )}
        {manualSmsModalOpen && !!st.address && (
          <ManualSmsModal
            intervention={it}
            initialValues={{ recipient: st.address, message: smsBody }}
            handleQueryError={() => {
              setSnackbarState({
                message: formatMessage({
                  defaultMessage: "SMS sending request failed",
                }),
                error: true,
              });
            }}
            handleQuerySuccess={() => {
              setSnackbarState({
                message: formatMessage({
                  defaultMessage: "SMS sending request sent",
                }),
              });
            }}
            onClose={() => {
              setManualSmsModalOpen(false);
            }}
          />
        )}
      </Box>
      {separator && <Divider sx={{ m: 0, pb: 1 }} />}
      <Snackbar onClose={snackbarOnClose} state={snackbarState} />
    </Box>
  );
};

const TaskListSubheader = styled("h3")(({ theme }) => ({
  position: "sticky",
  top: 0,
  zIndex: 1,

  boxSizing: "border-box",
  lineHeight: theme.spacing(6),

  color: theme.palette.text.secondary,
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: theme.palette.background.paper,

  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: 0,
  paddingBottom: 0,
  margin: 0,
}));

export const SubtasksWidget = ({
  intervention,
  interventionAllowed,
  isOwn,
  refetchIntervention,
  sx,
}: {
  intervention?: Intervention;
  interventionAllowed: boolean;
  isOwn: boolean;
  refetchIntervention: () => Promise<any>;
  sx?: SxProps;
}) => {
  const { formatMessage } = useIntl();
  const [unackCdecs] = useAcknowledge();

  const { mutate: updateSubtask } = useUpdate<Subtask>("alarm/subtasks");
  const isOpen = intervention?.["close-time"] === null;

  const { data: company } = useGetOne<CompanyInfo>("alarm/company-info", "1");

  const [error, setError] = useState<JsonapiError | null>(null);
  const handleSnackbarClose = () => {
    setError(null);
  };
  const handleQueryError = (error: JsonapiError) => {
    setError(error);
  };

  const setSubtaskStatus = (id: string, status: boolean) =>
    updateSubtask(
      { id, status },
      {
        onSettled: refetchIntervention,
        onError: handleQueryError,
      },
    );

  const toSimpleList = (
    { id, status, subtasks, "action-group": ag }: TaskGroup,
    j: number,
    arr: TaskGroup[],
  ) => (
    <Box key={id} m={0}>
      <TaskListSubheader
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {ag?.description ??
          formatMessage({
            defaultMessage: "Deleted task group",
            description: "Deleted task group message",
          })}
        {status && <DoneIcon color="primary" sx={{ marginLeft: "auto" }} />}
      </TaskListSubheader>
      {subtasks?.map((element, i) => (
        <STListItem
          key={element.id}
          it={intervention}
          st={element}
          ag={ag}
          cmp={company ?? null}
          setSubtaskStatus={setSubtaskStatus}
          readonly={!interventionAllowed || !isOwn || !isOpen}
          separator={i !== subtasks.length - 1 || j !== arr.length - 1}
          sx={{ mt: i === 0 ? 0 : 1 }}
        />
      ))}
    </Box>
  );

  return (
    <>
      <Widget
        title={formatMessage({
          defaultMessage: "Tasks",
          description: "Intervention page Tasks widget title",
        })}
        postfix={
          !isOpen ? (
            <Typography>
              {formatMessage({ defaultMessage: "Closed intervention" })}
            </Typography>
          ) : (
            unackCdecs.length > 0 && (
              <BootstrapTooltip
                backgroundColor="info.main"
                title={formatMessage({
                  defaultMessage: "Unacknowledged events !",
                })}
              >
                <PriorityHighIcon
                  fontSize="small"
                  sx={{ color: "common.white" }}
                />
              </BootstrapTooltip>
            )
          )
        }
        placeholderMessage={formatMessage({
          defaultMessage: "No tasks",
          description: "Tasks widget No tasks message",
        })}
        sx={sx}
      >
        {intervention?.["task-groups"]?.length && (
          <Box
            display="flex"
            flexDirection="column"
            sx={{ overflowX: "hidden", overflowY: "auto" }}
          >
            {intervention["task-groups"].map(toSimpleList)}
          </Box>
        )}
      </Widget>
      <JsonApiErrorSnackbar error={error} onClose={handleSnackbarClose} />
    </>
  );
};
